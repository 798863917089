.step-item {
  display: flex;
  flex-direction: column;
  span {
    font-size: 14.4rem;
    color: $purple-dark;
  }
  h4 {
    font-family: 'Gilroy Semibold';
    font-size: 2.2rem;
    color: $light;
    margin-top: 3rem;
  }
  p {
    font-family: 'Gilroy';
    font-size: 1.8rem;
    color: $gray;
    margin-top: 3rem;
  }
  .description-wrapper {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    .description-item {
      display: flex;
      align-items: center;
      grid-gap: 2rem;
      gap: 2rem;
      div {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #FF004A;
        flex-shrink: 0;
      }
      span {
        font-family: 'Gilroy';
        font-size: 1.8rem;
        color: $gray;
      }
    }
  }
}