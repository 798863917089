/* ********** Colors Start ********** */

$light: #ffffff;
$dark: #1B1A34;
$primary: #FF004A;
$purple: #31305E;
$purple-light: #6965FF;
$purple-medium: #31305D;
$purple-dark: #3B3A69;
$gray: #A6B1E8;
$blue: #13132e;

/* ********** Colors End ********** */
