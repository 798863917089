.game-card {
  background-color: $dark;
  border-radius: 60px;
  padding: 50px;
  margin: 0 20px;
  transition: 0.35s ease-in-out;
  flex-shrink: 0;
  .image-wrapper {
   width: 100%;
    height: 212px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }
  .devices-wrapper {
    margin-top: 4.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $gray;
    span {
      font-size: 1.8rem;
      font-family: 'Gilroy';
    }
  }
  h3 {
    margin-top: 4.2rem;
    color: $light;
    font-family: 'Gilroy';
    font-size: 3.6rem;
    text-transform: capitalize;
  }
  h4 {
    margin-top: 3rem;
    color: $primary;
    font-size: 4.8rem;
    span {
      font-size: 3rem;
      color: $gray;
    }
  }
  p {
    margin-top: 2.4rem;
    font-size: 1.8rem;
    font-family: 'Gilroy';
    color: $gray;
  }
  .button-wrapper {
    margin-top: 4rem;
  }
}