.contact-form {
  .button {
    margin-top: 2.4rem;;
  }
}

@media screen and (max-width: 640px) {
  .contact-form {
    .button {
      width: 100%;
    }
  }
}