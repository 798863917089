.input {
  display: flex;
  flex-direction: column;
  input, textarea {
    background: $blue;
    border-radius: 18px;
    padding: 0 1rem;
    width: 100%;
    font-size: 1.6rem;
    font-family: 'Gilroy';
    color: $gray;
    &::placeholder {
      color: $gray;
    }
  }
  input {
    height: 5.6rem;
  }
  textarea {
    height: 16rem;
    padding-top: 1.4rem;
  }
  & + .input {
    margin-top: 2.4rem;
  }
}