.benefits {
  .benefits-container {
    display: flex;
    justify-content: center;
    gap: 8.3rem;
  }
  .benefits-wrapper {
    padding: 3.6rem;
    background-color: $dark;
    border-radius: 30px;
    font-family: 'Gilroy';
    color: $gray;
    font-size: 1.8rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .benefits-item {
      display: flex;
      align-items: center;
      gap: 2rem;
      div {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $primary;
        flex-shrink: 0;
      }
    }
  }
  &.concated {
    background-color: $dark;
    border-radius: 30px;
    width: 80%;
    padding: 3.6rem 0;
    .benefits-wrapper {
      background-color: transparent;
      border-radius: 0;
      padding: 0;
      width: unset;
    }
  }
}

@media screen and (max-width: 640px) {
  .benefits {
    .benefits-container {
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }
    .benefits-wrapper {
      width: 100%;
    }
    &.concated {
      width: 100%;
      display: flex;
      justify-content: center;
      .benefits-container {
        align-items: flex-start;
      }
    }
  }
}