.find-us {
  .title {
    font-size: 2.2rem;
    color: $light;
    margin-bottom: 3rem;
    text-align: center;
  }
  .links {
    display: flex;
    align-items: center;
    gap: 3.6rem;
    a {
      width: 56px;
      height: 56px;
      border-radius: 18px;
      background-color: $gray;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $dark;
      font-size: 1.8rem;
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 640px) {
  .find-us {
    order: 1;
    .title {
      text-align: left;
    }
  }
}