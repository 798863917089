.navigation {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  margin: 1rem 0 1rem auto;
  padding: 0 1.5rem;
  &.burger {
    justify-content: flex-end;
  }
  .nav-item {
    font-size: 1.8rem;
    color: $light;
    text-transform: capitalize;
    transition: color 0.35s ease-in-out;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    & + .nav-item {
      margin-left: 7rem;
    }
    @media screen and (max-width: 964px) {
      & + .nav-item {
        margin-left: 5rem;
      }
    }
    &.active, &:hover {
      color: $primary;
    }
  }
}
.burger-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  cursor: pointer;
  span {
    width: 20px;
    height: 2px;
    background-color: $light;
  }
}

.expended-navigation {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #111126;
  color: $light;
  z-index: 1000;
  .nav-item-holder {
    height: 8rem;
    display: flex;
    align-items: center;
    font-size: 2rem;
    text-transform: capitalize;
    transition: 0.35s ease-in-out;
    cursor: pointer;
    &.column {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      overflow: hidden;
    }
    &.active {
      height: fit-content;
      .n-item-info {
        height: 8rem;
        display: flex;
        align-items: center;
        color: $primary;
        width: 100%;
        i {
          transform: rotate(180deg);
        }
      }
      .sub-nav-item{
        background-color: $purple;
        width: 100%;
        padding: 2rem 0;
        .sub-nav-list {
          height: auto;
          padding-left: 2.5rem;
        }
      }
      &:hover {
        background-color: transparent;
      }
    }
    & + .nav-item-holder {
     border-top: 1px solid $purple;
    }
    &:hover {
      background-color: $purple;
      .nav-i-text, i {
        color: $primary;
      }
    }
    .n-item-info{
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      display: flex;
      align-items: center;
      width: 100%;
      i {
        margin-left: auto;
        transition: 0.35s ease-in-out;
      }
      .nav-i-text{
        transition: 0.35s ease-in-out;
      }
    }
    .sub-nav-item {
      .sub-nav-list {
        display: flex;
        flex-direction: column;
        height: 0;
        overflow: hidden;
        gap: 2rem;
        .s-nav-link {
          transition: 0.35s ease-in-out;
          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}