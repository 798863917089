.footer-contact {
  .footer-contact-inner {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    @media screen and (max-width: 640px) {
      flex-direction: column;
      justify-content: flex-start;
      gap: 4rem;
    }
  }
}