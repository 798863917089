.section-title {
  margin: 20rem auto 8rem;
  max-width: 95rem;
  text-align: center;
  @media screen and (max-width: 640px) {
    margin: 8rem auto;
  }
  .title {
    font-size: 4.8rem;
    .common {
      color: $light
    }
    .highligh {
      margin-left: 10px;
      color: $primary;
    }
  }
  .sub-title-white {
    font-size: 2.4rem;
    color: $light;
    margin-top: 4.8rem;
  }
  .sub-title {
    font-family: 'Gilroy';
    font-weight: 400;
    margin-top: 2.4rem;
    color: $gray;
    font-size: 1.8rem;
  }
}