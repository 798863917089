@import './ContactForm.scss';

.contacts {
  .contacts-inner {
    background-color: $purple-medium;
    border-radius: 30px;
    padding: 4.8rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    .info {
      width: 39%;
      .title {
        font-size: 4.8rem;
        color: $light;
      }
      .description, .sub-description {
        margin-top: 4.8rem;
        font-size: 2rem;
      }
      .description {
        color: $gray;
      }
      .sub-description {
        color: $light;
        margin-top: 4.8rem;
      }
    }
    .contact-form {
      width: 28%;
      margin-left: 6%;
    }
  }
}

@media screen and (max-width: 640px) {
  .contacts {
    .container {
      display: contents
    }
    .contacts-inner {
      display: flex;
      flex-direction: column;
      padding: 2.4rem;
      border-radius: 0;
      .info {
        width: 100%;
      }
      .contact-form {
        margin-left: 0;
        margin-top: 4rem;
        width: 100%;
      }
    }
  }
}
