@font-face {
  font-family: 'gamblebeast';
  src:  url('fonts/gamblebeast.eot?gm68gq');
  src:  url('fonts/gamblebeast.eot?gm68gq#iefix') format('embedded-opentype'),
    url('fonts/gamblebeast.ttf?gm68gq') format('truetype'),
    url('fonts/gamblebeast.woff?gm68gq') format('woff'),
    url('fonts/gamblebeast.svg?gm68gq#gamblebeast') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'gamblebeast' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-instagram:before {
  content: "\e900";
}
.icon-quality:before {
  content: "\e901";
}
.icon-digitalization:before {
  content: "\e902";
}
.icon-innovation:before {
  content: "\e903";
}
.icon-partnership:before {
  content: "\e904";
}
.icon-slide-right:before {
  content: "\e905";
}
.icon-Vector-19:before {
  content: "\e906";
}
.icon-email:before {
  content: "\e907";
}
.icon-phone:before {
  content: "\e908";
}
.icon-certified:before {
  content: "\e90b";
}
.icon-performing:before {
  content: "\e90c";
}
.icon-fast:before {
  content: "\e90d";
}
.icon-reporting:before {
  content: "\e90e";
}
.icon-bonus:before {
  content: "\e90f";
}
.icon-profitability:before {
  content: "\e910";
}
.icon-engaging:before {
  content: "\e911";
}
.icon-video:before {
  content: "\e912";
}
.icon-fraud:before {
  content: "\e915";
}
.icon-trader:before {
  content: "\e916";
}
.icon-odds:before {
  content: "\e917";
}
.icon-markets:before {
  content: "\e918";
}
.icon-twitter:before {
  content: "\e91a";
}
.icon-facebook:before {
  content: "\e91b";
}
.icon-mail:before {
  content: "\e91c";
}
.icon-linkdin:before {
  content: "\e91d";
}
.icon-scalability:before {
  content: "\e920";
}
.icon-transparency:before {
  content: "\e921";
}
.icon-expertise:before {
  content: "\e922";
}
.icon-speed:before {
  content: "\e923";
}
.icon-sportsbook:before {
  content: "\e924";
}
.icon-casino:before {
  content: "\e925";
}
.icon-platform:before {
  content: "\e926";
}
.icon-arrow-down:before {
  content: "\e927";
}
.icon-cross:before {
  content: "\e91f";
}
