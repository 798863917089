.key-info {
  display: flex;
  flex-direction: column;
  font-family: 'Gilroy';
  width: 50%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      font-size: 6.4rem;
      color: $primary;
    }
    .cost {
      padding: 1rem 2rem;
      background: $purple-medium;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 3.2rem;
        color: $gray
      }
    }
  }
  .body {
    display: flex;
    align-items: center;
    gap: 2.2rem;
    margin-top: 0.6rem;
    h3 {
      font-size: 6.4rem;
      color: $light;
    }
    a {
      i {
        font-size: 1.8rem;
        color: $gray;
      }
    }
  }
  p {
    margin-top: 2.4rem;
    color: $gray;
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 850px) {
  .key-info {
    width: 80%;
    .header {
      h4 {
        font-size: 3rem;
      }
      .cost {
        span {
          font-size: 1.8rem;
        }
      }
    }
    .body {
      h3 {
        font-size: 3rem;
      }
    }
    p {
      font-size: 1.8rem;
      line-height: 2.6rem;
    }
  }
}

.no-image {
  .key-info {
    width: auto;
  }
}