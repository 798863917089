.copyright {
  margin-top: 8.6rem;
  text-align: center;
  p {
    font-family: 'Gilroy';
    color: $gray;
    font-size: 1.6rem;
    span {
      color: $primary;
      padding-left: 0.5rem;
    }
  }
}