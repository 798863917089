.partners {
  .partners-inner {
    .partners-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2.4rem;
      @media (max-width: 630px) {
        gap: 2rem;
      }
      .partners-item {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: calc(100% / 14 * 2 - 2.4rem);
        @media (max-width: 1132px) {
          width: calc(100% / 10 * 2 - 2.4rem);
        }
        @media (max-width: 780px) {
          width: calc(100% / 8 * 2 - 2.4rem);
        }
        @media (max-width: 630px) {
          width: calc(100% / 9 * 3 - 2rem);
        }
        @media (max-width: 450px) {
          width: calc(100% / 10 * 5 - 2rem);
        }
        .image-wrapper {
          height: 12rem;
          background-color: $dark;
          border-radius: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 50%;
            height: 50%;
            object-fit: contain;
          }
        }
        span {
          font-size: 1.8rem;
          color: $light;
          margin-top: 1.4rem;
          font-family: 'Gilroy Semibold';
        }
      }
    }
  }
}