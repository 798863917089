@import './FooterContact.scss';
@import './ContactUs.scss';
@import './Findus.scss';
@import './GetProductInfo.scss';
@import './Copyright.scss';

.app-footer {
  padding-bottom: 2.5rem;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 25%;
    background: url('./subtract.png') no-repeat right/contain;
    pointer-events: none;
  }
  & > * {
    position: relative;
    z-index: 1;
  }
}