@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Regular'), local('Gilroy-Regular'),
      url('../../assets/fonts/Gilroy-Regular.woff2') format('woff2'),
      url('../../assets/fonts/Gilroy-Regular.woff') format('woff'),
      url('../../assets/fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy Semibold';
  src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
  url('../../assets/fonts/Gilroy-Semibold.woff2') format('woff2'),
  url('../../assets/fonts/Gilroy-Semibold.woff') format('woff'),
  url('../../assets/fonts/Gilroy-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy Bold';
  src: local('Gilroy Bold'), local('Gilroy-Bold'),
      url('../../assets/fonts/Gilroy-Bold.woff2') format('woff2'),
      url('../../assets/fonts/Gilroy-Bold.woff') format('woff'),
      url('../../assets/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}