@import './global';

*, *::before, *:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  @media screen and (max-width: 1200px) {
    font-size: 54.5%;
  }
  @media screen and (max-width: 964px) {
    font-size: 46.5%;
  }
}

body {
  font-family: 'Gilroy Bold';
  background-color: $blue;
  &.overflow-hidden {
    overflow: hidden;
  }
}

.container {
  max-width: 1440px;
  padding: 0 1.5rem;
  margin: 0 auto;
  height: 100%;
  @media screen and (max-width: 640px) {
    padding: 0 2.4rem;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
}

.app {
  height: 100vh;
}

.section {
  margin-bottom: 20rem;
  @media screen and (max-width: 640px) {
    transform: translateY(0);
    margin-bottom: 8rem;
  }
  &.primary {
    position: relative;
    background-color: $primary;
    padding-top: 8rem;
    margin-bottom: 27.5rem;
    @media screen and (max-width: 640px) {
      margin-bottom: 8rem;
      padding: 4rem 0;
    }
    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 86%;
      height: 100%;
      background: url('./subtract.png') no-repeat right/cover;
      z-index: 1;
    }
    .section-title {
      margin: 0 auto;
      position: relative;
      z-index: 2;
    }
    .section-title + div {
      transform: translateY(7.5rem);
      position: relative;
      z-index: 2;
      @media screen and (max-width: 640px) {
        transform: translateY(0);
        margin-top: 4rem;
      }
    }
  }
  &:last-child{
    margin-bottom: 0;
  }
}

.line-height {
  line-height: 3rem;
}

input, textarea {
  all: unset;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@import './components';
@import './pages';