.features {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 6rem;
  .feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
    gap: 1rem;
    h3 {
      font-family: 'Gilroy';
      font-size: 5.6rem;
      color: $purple-dark;
    }
    h4 {
      font-family: 'Gilroy Semibold';
      font-size: 2.2rem;
      color: $light;
    }
  }
}

@media screen and (max-width: 640px) {
  .features {
   flex-direction: column;
   align-items: flex-start;
   .feature {
    align-items: flex-start;
   }
  }
}