.list-of-features {
  background-color: $dark;
  padding: 3.6rem 6.4rem;
  border-radius: 30px;
  color: $gray;
  font-size: 1.8rem;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3.6rem;
    background-color: $primary;
    border-radius: 50%;
    width: 0.8rem;
    height: 0.8rem;
  }
}