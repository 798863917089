.contact-us {
  @media screen and (max-width: 640px) {
    order: 2;
  }
  .title {
    font-size: 2.2rem;
    color: $light;
    margin-bottom: 3rem;
  }
  .contacts {
    display: flex;
    flex-direction: column;
    gap: 2.8rem;
    .contact-item {
      display: flex;
      i {
        color: $primary;
        font-size: 2.6rem;
      }
      a {
        margin-left: 2rem;
        font-size: 1.8rem;
        color: $gray;
        font-family: 'Gilroy';
      }
    }
  }
}