@import './CardItem.scss';

.cards {
  .cards-inner {
    @include grid(300px, 3rem);
  }
  &.dark {
    .cards-inner {
      @include grid(350px, 3rem);
      @media screen and (max-width: 640px) {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      }
    }
    .card-item{
      background-color: $dark;
      padding: 5rem 4rem;
      &:hover {
        background-color: $purple;
        .header {
          i {
            color: $primary
          }
        }
      }
      .header {
        padding-left: 12px;
        i {
          font-size: 7.5rem;
        }
        .title {
          font-family: 'Gilroy';
          margin-left: 3.2rem;
          font-size: 3.6rem;
          color: $light;
        }
      }
      .body {
        margin-top: 3.8rem;
        p {
          color: $gray;
        }
      }
    }
  }
}