.payments {
  .payment-list-wrapper {
    display: flex;
    justify-content: center;
    .payments-list {
      @include scrollGrid(5, 10px, true);
      padding-bottom: 1rem;
      @media (max-width: 730px) {
        @include scrollGrid(3, 10px, true);
      }
      @media (max-width: 450px) {
        @include scrollGrid(2, 10px, true);
      }
      .payment-item {
        max-width: 100%;
        height: 120px;
        border-radius: 30px;
        background-color: $dark;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 50%;
          height: 50%;
          object-fit: contain;
        }
      }
    }
  }
}