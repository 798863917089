.button {
  height: 5.6rem;
  border-radius: 24px;
  border: 2px solid $purple-dark;
  background-color: transparent;
  font-size: 1.6rem;
  color: $gray;
  padding: 0 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.2s;
  cursor: pointer;
  &:hover {
    background-color: $purple-dark;
  }
  &.primary{
    background-color: $primary;
    color: $blue;
    border: 2px solid transparent;
    &:hover {
      background-color: darken($primary, 10%);
    }
  }
  i {
    margin-right: 1rem;
    transform: translateY(0.1rem);
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}