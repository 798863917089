@mixin grid ($min, $row-gap, $column-gap: 2rem) {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax($min, 1fr));
  grid-row-gap: $row-gap;
  grid-column-gap: $column-gap;
}

@mixin scrollGrid ($n, $g, $horizontal) {
  display:grid;
  grid-auto-flow: column;
  grid-auto-columns:calc((100% - (#{$n} - 1) * #{$g}) / #{$n}); 
  grid-gap: #{$g};
  max-width:100%;
  overflow:auto;
  &::-webkit-scrollbar {
    @if($horizontal) {
      height: 4px;
    } @else {
      width: 4px;
    }
  }

  &::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $gray;
  border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
  }
}