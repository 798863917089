.key-components {
  .key-components-inner {
    display: flex;
    flex-direction: column;
    gap: 24rem;
    .item {
      display: flex;
      align-items: center;
      gap: 11.6rem;
      .image-wrapper {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          z-index: 1;
          &[alt="live-casino"],
          &[alt="score"],
          &[alt="streaming"],
          &[alt="odds"] {
            width: 60%;
          }
          &[alt="slots"] {
            width: 80%;
          }
        }
      }
      &.odd{
        flex-direction: row-reverse;
      }
      &.subtract {
        .image-wrapper {
          &:before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 0;
          }
        }
        &.even {
          .image-wrapper {
            &:before {
              right: 0;
              background: url('./images/right-subtract.png') no-repeat center/contain;
            }
          }
        }
        &.odd {
          .image-wrapper {
            &:before {
              left: 0;
              background: url('./images/left-subtract.png') no-repeat center/contain;
            }
          }
        }
      }
    }
    &.no-image {
      @include grid(500px, 8.4, 8.4rem);
    }
  }
}

@media screen and (max-width: 850px) {
  .key-components {
    .key-components-inner {
      gap: 4.2rem;
      .item {
        gap: 2.2rem;
        flex-direction: column-reverse;
        .image-wrapper {
          width: 50%;
        }
        &.odd {
          flex-direction: column-reverse;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .key-components {
    .key-components-inner {
      &.no-image {
        display: flex;
        flex-direction: column;
      }
    }
  }
}