.carousel {
  margin-top: 12.2rem;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 86%;
    height: 100%;
    background: url('./subtract.png') no-repeat right/contain;
    pointer-events: none;
  }
  .carousel-inner {
    position: relative;
    overflow: hidden;
    height: 100%;
    .carousel-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 12rem;
      .left {
        width: 54%;
        position: relative;
        .section-title {
          margin: 0 auto 8rem;
          text-align: left;
        }
        .sub-title {
          min-height: 90px;
        }
      }
      .right {
        flex-grow: 1;
        margin-left: 10rem;
        height: auto;
        object-fit: cover;
        object-position: center;
        background: radial-gradient(50% 50% at 50% 50%, rgba(196, 196 ,196, 0.1) 0%, rgba(196, 196, 196, 0) 100%);
        position: relative;
        min-height: 522px;
        &.features {
          background: transparent;
          mix-blend-mode: unset;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        img {
          width: 100%;
          height: 100%;
          position: relative;
          z-index: 1;
        }
      }
    }
    .dots-wrapper {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .dot {
        width: 1.2rem;
        height: 2rem;
        border-radius: 5px;
        background-color: $purple-medium;
        cursor: pointer;
        &.active {
          background-color: $primary;
        }
        & + .dot {
          margin-top: 4rem;
        }
      }
    }
  }
}

@media screen and (max-width: 964px) {
  .carousel {
    margin-top: 4rem;
    .carousel-inner  {
      overflow: unset;
      &::before {
        content: none;
      }
      .carousel-item {
        flex-direction: column-reverse;
        justify-content: flex-start;
        height: auto;
        padding-left: 0;
        .left {
          margin-top: 6rem;
          width: 100%;
          .section-title {
            margin: 0 0 6rem;
          }
        }
        .right {
          margin-left: 0;
        }
      } 
      .dots-wrapper {
        flex-direction: row;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 4rem);
        .dot {
          height: 1.2rem;
          width: 2rem;
          & + .dot {
            margin-top: 0;
            margin-left: 1.4rem;
          }
        }
      }
    }
  }
}