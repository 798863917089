.live-casino-games {
  .live-casino-games-inner {
    display: flex;
    flex-direction: column;
    gap: 10rem;
    .live-casino-games-item {
      display: flex;
      flex-direction: column;
      gap: 8rem;
      .texts-buttons-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .buttons {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        button {
          width: 7.5rem;
          height: 7.5rem;
          background-color: $dark;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $light;
          font-size: 1.8rem;
          cursor: pointer;
          &:disabled,
          &[disabled] {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
      }
      .texts {
        display: flex;
        align-items: center;
        gap: 3.2rem;
        h4 {
          font-family: 'Gilroy';
          font-size: 6.4rem;
          color: $primary;
        }
        h3 {
          font-size: 6rem;
          color: $light;
        }
      }
    }
  }
}