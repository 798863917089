.card-item {
  background-color: $light;
  padding: 3.6rem;
  border-radius: 30px;
  font-family: 'Gilroy';
  display: flex;
  flex-direction: column;
  transition: 0.35s ease-in-out;
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 3.2rem;
    i {
      color: $purple-light;
      font-size: 4.5rem;
      transition: 0.35s ease-in-out;
      flex-grow: 1;
      text-align: center;
    }
    .title {
      font-family: 'Gilroy Semibold';
      margin-left: 2rem;
      font-size: 2.2rem;
      color: $primary;
      width: 100%;
    }
  }
  .body {
    p {
      color: $blue;
      font-size: 1.8rem;
      &.bold {
        font-family: 'Gilroy Semibold';
        text-align: center;
        font-size: 2.2em;
      }
    }
  }
  .footer {
    margin-top: auto;
    padding-top: 5rem;
  }
}