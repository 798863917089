.modal-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1200;
  .modal {
    width: 375px;
    max-height: 90vh;
    background-color: $purple-medium;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 2rem;
    font-size: 2rem;
    .modal-body {
      .contact-form {
        margin-left: -1rem;
      }
      .input {
        input {
          height: 2.6rem;
        }
        input, textarea {
          padding: 1rem 0;
          padding-left: 1rem;
        }
        padding: 0;
        padding-left: 1rem;
      }
      .button {
        margin: 2.4rem auto 0;
      }
    }
    .modal-header {
      font-weight: 600;
      padding-bottom: 1rem;
      text-transform: capitalize;
      text-align: right;
      i {
        color: $light;
        margin-left: auto;
        cursor: pointer;
      }
    }
  }
}